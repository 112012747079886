import { Container } from "react-bootstrap";
import {createRef} from "react";

import "./../styles/pages/home.scss";

import Header from "./../components/home/header/Header";
import Achievements from "../components/home/achievements/Achievements";
import Footer from "../components/common/footer/Footer";
import Rates from "./../components/home/rates/Rates";
import LaborInformation from "../components/home/labor-information/LaborInformation";
import AboutMe from "../components/home/about-me/AboutMe";

const Home = () => {
    let scrollToBody = createRef();

    return (
        <>
            <Header scrollToBody={scrollToBody} />

            {/*<Navbar />*/}

            <Container fluid ref={scrollToBody}>
                <AboutMe />
                <LaborInformation />
            </Container>

            {/*<Services />*/}

            <Container fluid>
                <Rates />
                <Achievements />
            </Container>

            <Footer />
        </>
    );
}

export default Home;
