import "./achievements.scss";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";
import {BrowserView, MobileView} from 'react-device-detect';

import firstPicture from "./../../../assets/achievements/achievement-1.jpg"
import secondPicture from "./../../../assets/achievements/achievement-2.jpg"
import thirdPicture from "./../../../assets/achievements/achievement-3.jpg"
import fourthPicture from "./../../../assets/achievements/achievement-4.jpg"

const Achievements = () => {
    return (
        <section id="achievements" className="text-center">
            <Fade bottom>
                <p className="section-title">~ Mes dernières réalisations ~</p>
            </Fade>

            <div className="achievements-picture">
                <MobileView>
                    <Roll left>
                        <img src={firstPicture} className="picture" alt="first achievement" />
                        <img src={secondPicture} className="picture" alt="second achievement" />

                    </Roll>

                    <img src={thirdPicture} className="picture" alt="third achievement" />
                    <img src={fourthPicture} className="picture" alt="fourth achievement" />
                    &nbsp;
                </MobileView>

                <BrowserView>
                    <Roll left>
                        <img src={firstPicture} className="picture" alt="first achievement" />
                    </Roll>
                    <Roll left delay={200}>
                        <img src={secondPicture} className="picture" alt="second achievement" />
                    </Roll>
                    <Roll left delay={400}>
                        <img src={thirdPicture} className="picture" alt="third achievement" />
                    </Roll>
                    <Roll left delay={600}>
                        <img src={fourthPicture} className="picture" alt="fourth achievement" />
                    </Roll>
                </BrowserView>
            </div>
        </section>
    );
};

export default Achievements;
