import "./footer.scss";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {Col, Container, Row} from "react-bootstrap";

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row className="justify-content-center">
                    <Col md={{ span: 5 }} xs={{ span: 12 }}>
                        <p className="footer-section-title">Contact</p>
                        <ul>
                            <li>Tél : 06.08.15.12.67</li>
                        </ul>

                        <p className="footer-section-title mt-md-5">Réseaux sociaux</p>
                        <ul>
                            <li><a href="https://www.instagram.com/cassandraguilmain" target="_blank" rel="noreferrer" >Instagram</a></li>
                            <li><a href="https://www.facebook.com/cassandraguilmain62" target="_blank" rel="noreferrer" >Facebook</a></li>
                        </ul>
                    </Col>

                    <Col md={{ span: 5, offset: 1 }} xs={{ span: 12 }}>
                        <p className="footer-section-title">Adresse</p>
                        <p>6 Rue de l'usine, Blingel, 62770</p>
                        <MapContainer center={[50.40878690301352, 2.149866650765015]} zoom={12} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[50.40878690301352, 2.149866650765015]}>
                                <Popup>
                                    Jusqu'au bout des ongles <br />
                                    6 Rue de l'usine <br />
                                    Blingel, 62770
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
