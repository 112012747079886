import "./header.scss";
import Zoom from "react-reveal/Zoom";

const Header = (props) => {
    const scrollToBody = () => {
        props.scrollToBody.current.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <header>
            <Zoom duration={1000}>
                <div className="title">
                    Jusqu'au bout des ongles
                </div>
            </Zoom>


            <Zoom delay={500} duration={1000}>
                <div className="sub-title">
                    Prothésiste Ongulaire
                </div>
            </Zoom>
            <span className="down-arrow" onClick={scrollToBody} />
        </header>
    );
};

export default Header;
