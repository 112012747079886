import "./labor-information.scss";
import {Col, Container, Row} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const LaborInformation = () => {
    return (
        <section id="labor-information">
            <Container>
                <Row className="justify-content-around">
                    <Col md={{ span: 3 }} xs={{ span: 12 }}>
                        <div className="labor-information-section">
                            <Fade bottom>
                                <h2>Rendez-vous</h2>
                                <p>
                                    Mes horaires sont : <br />
                                    Du lundi au vendredi 9H - 18H <br />
                                    Le samedi de 8H à 11H. <br /><br />
                                    Vous pouvez prendre rendez-vous en me contactant par téléphone au 06 08 15 12 67.
                                </p>
                            </Fade>
                        </div>
                    </Col>


                    <Col md={{ span: 3 }} xs={{ span: 12 }}>
                        <div className="labor-information-section">
                            <Fade bottom>
                                <h2>
                                    + &nbsp;
                                    <CountUp end={300} redraw={false}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                    &nbsp; Couleurs
                                </h2>
                                <p>
                                    J’utilise plusieurs marques qui sont toutes certifiées, je vous laisse le loisir
                                    de regarder parmi mon nuancier lors de votre visite, vous
                                    trouverez forcément votre bonheur pour réaliser la manucure de votre choix.
                                </p>
                            </Fade>
                        </div>
                    </Col>

                    <Col md={{ span: 3 }} xs={{ span: 12 }}>
                        <div className="labor-information-section">
                            <Fade bottom>
                                <h2>Hygiène</h2>
                                <p>
                                    Mes ustensiles sont stérilisés à chaque prestation afin d’éliminer tout microbe.
                                    Du gel hydroalcoolique est à votre disposition.
                                </p>
                            </Fade>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default LaborInformation;
