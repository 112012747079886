import "./about-me.scss";
import {Col, Container, Row} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import aboutMePicture from "./../../../assets/about-me/about-me.jpg"

const AboutMe = () => {
    return (
        <section id="about-me">
            <Container>
                <Fade bottom>
                    <Row>
                        <Col md={{ span: 5 }} xs={{ span: 12 }}>
                            <h2 className="about-me-title d-md-none">Qui suis-je ?</h2>
                            <img src={aboutMePicture} alt="me" />
                        </Col>

                        <Col md={{ span: 7 }} xs={{ span: 12 }}>
                            <div className="labor-information-section">
                                <h2 className="about-me-title d-none d-md-block">Qui suis-je ?</h2>
                                <p>
                                    Le monde de la comptabilité ne me correspondait plus donc j’ai décidé de me reconvertir en tant que prothésiste ongulaire, les ongles sont une vraie passion pour moi.
                                    <br />
                                    <br />
                                    J’ai donc décidé de faire une formation sur Bordeaux pour pouvoir vous proposer des manucures dans les règles de l’art et que vous soyez belles jusqu’au bout des ongles.
                                    <br />
                                    <br />
                                    Je suis quelqu’un de joyeuse et enthousiaste, je vous réserve un accueil chaleureux pour que vous puissiez passer en ma compagnie un moment de détente et de tranquillité.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </section>
    );
};

export default AboutMe;
