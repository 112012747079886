import "./rates.scss";
import Fade from "react-reveal/Fade";
import {Col, Row} from "react-bootstrap";

const Rates = () => {
    const rates = [
        {
            "name": "Poses :",
            "items": [
                {
                    "name": "Vernis semi permanent (mains)",
                    "price": "30 €"
                },
                {
                    "name": "Vernis semi permanent (pieds)",
                    "price": "30 €"
                },
                {
                    "name": "Gainage",
                    "price": "43 €"
                },
                {
                    "name": "Extension chablon",
                    "price": "50 €"
                },
                {
                    "name": "Supplément taille XL (> taille 2)",
                    "price": "+5 €"
                },
                {
                    "name": "Supplément taille XXL (> taille 4)",
                    "price": "+7 €"
                },
            ],
        },
        {
            "name": "Remplissages :",
            "items": [
                {
                    "name": "Sans casse",
                    "price": "40 €"
                },
                {
                    "name": "Supplément taille XL (> taille 2)",
                    "price": "+5 €"
                },
                {
                    "name": "Supplément taille XXL (> taille 4)",
                    "price": "+7 €"
                },
                {
                    "name": "Avec casse",
                    "price": "+3€/ongle"
                },
            ],
        },
        {
            "name": "Déposes :",
            "items": [
                {
                    "name": "Sans soin",
                    "price": "15 €"
                },
                {
                    "name": "Avec un soin",
                    "price": "20 €"
                },
            ],
        },
        {
            "name": "Nail art :",
            "items": [
                {
                    "name": "Dégradé de couleurs, cat eyes, foils, poudre chrome, stamping, stickers",
                    "price": "1 €/ongle"
                },
                {
                    "name": "Nail art simple",
                    "price": "2 €/ongle"
                },
                {
                    "name": "Nail art moyen",
                    "price": "3 €/ongle"
                },
                {
                    "name": "Nail art plus élaboré",
                    "price": "4 €/ongle"
                },
                {
                    "name": "Bijou en strass ou doigt rempli",
                    "price": "4 €/ongle"
                },
            ],
        },
        {
            "name": "Cadeau :",
            "items": [
                {
                    "name": "Mois de votre anniversaire",
                    "price": "-5€"
                },
            ],
        },
    ];

    return (
        <section id="my-rates">
            <Fade bottom>
                <div className="text-center">
                    <p className="section-title">~ Mes Tarifs ~</p>
                </div>
            </Fade>

            <Row className="justify-content-center">
                <Col lg={{ span: 5 }} md={{ span: 10 }} xs={{ span: 12 }}>
                    <table>
                        <tbody>
                            {
                                rates.map((section, index) => {
                                    return (
                                        <>
                                            <Fade bottom>
                                                <tr>
                                                    <td><strong>{section.name}</strong></td>
                                                    {
                                                        (typeof section.price !== "undefined")
                                                        ? <td className="text-end">{section.price}</td>
                                                        : <td />
                                                    }
                                                </tr>
                                            </Fade>
                                            {
                                                section.items.map((item) => {
                                                    return (
                                                        <Fade bottom>
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td className="text-end">{item.price}</td>
                                                            </tr>
                                                        </Fade>
                                                    );
                                                })
                                            }
                                            {
                                                (rates.length - 1) !== index &&
                                                <Fade bottom>
                                                    <tr className="new-table-section">
                                                        <td><hr /></td>
                                                        <td><hr /></td>
                                                    </tr>
                                                </Fade>
                                            }
                                        </>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </section>
    );
};

export default Rates;
